import baseContextComponent from "@/components/baseContextComponent";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import api from "@/services/api";
import { Component } from "vue-property-decorator";

const _ENDPONT_TRANSACTION_FM_OK = api.endpoint.CARRELLO.KEYCLIENT_FINEMESE_OK;

@Component({})
export default class RitornoFineMeseOk extends baseUserContextComponent {
	loading: boolean = true;

	created() {
		var self = this;

		var queryString = this.$route.fullPath.split("?");
		if (queryString.length > 1) {
			var query = queryString.pop();
			api.get(`${_ENDPONT_TRANSACTION_FM_OK}?${query}`)
				.then(res => {
					if (res.redirectTo && res.redirectTo.length > 0 && (res.redirectTo == "ritorno-ko" || !self.userMustPay)) {
						self.$router.push({ name: res.redirectTo });
					}

					self.loading = false;
				})
				.catch(err => {
					console.log(err);
					self.$router.push({ name: "ritorno-ko" });
				});
		} else {
			this.$router.push({ name: "ritorno-ko" });
		}
	}

	goTo() {
		if (this.userMustPay) {
			this.$router.push({ name: "myoverins_tuoiPagamenti" });
		} else {
			this.$router.push({ name: "carrelloCategorie" });
		}
	}

	get labelActionToGo(): string  {
		if (this.userMustPay) {
			return this.$i18n.t("generico.vaiAiTuoiPagamenti").toString()
		}
		else {
			return this.$i18n.t("generico.vaiAlCarrello").toString()
		}
	}

	get descriptionNextActionToDo(): string  {
		if (this.userMustPay) {
			return this.$i18n.t("generico.continuaConITuoiPagamenti").toString()
		}
		else {
			return this.$i18n.t("generico.continuaConNuoveAdesioni").toString()
		}
	}
}
